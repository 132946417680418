
  /* eslint-disable */

  import { Component, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import LzTable from "@/components/Table.vue";
  import { namespace } from "vuex-class";
  import { apiOngs, apiBrand } from "../api";
  import { apiEvents } from "@/modules/calendar/api";
  import { parseFile } from "@/utils/parseFile";
  import LzEditorInput from "@/components/EditorInput.vue";
  import { GoogleAnalytics, ChooseTemplate } from "../components";
  import toBase64 from "@/utils/toBase64";
  import LzModal from "@/components/Modal.vue";
  import { checkSubscriptionPlan } from "@/utils";
  import cloneDeep from "lodash/cloneDeep";
  import isEqual from "lodash/isEqual";
  import isObjectEmpty from "lodash/isEmpty";

  const auth = namespace("auth");

  @Component({
    components: {
      LzButton,
      LzTable,
      LzEditorInput,
      ChooseTemplate,
      LzModal,
      GoogleAnalytics
    }
  })
  export default class Public extends Vue {
    testEditor = "";
    showModal = false;
    showModalDesign = false;
    modalText = {
      title: "",
      subtitle: ""
    };

    isPremiumSubscription = false;
    @auth.State("ongConfiguration")
    public ongConfiguration!: any;

    handleChooseTemplate(e: Event & { target: HTMLInputElement }) {
      if (isObjectEmpty(this.prevForm)) return;
      this.publicWebForm.chosenTemplateId = e.target.value;
    }

    onModalClose(): void {
      this.showModal = false;
    }

    onModalCloseDesign(): void {
      this.showModalDesign = false;
    }

    onModalOpen(): void {
      this.showModal = true;
    }

    onModalOpenDesign(): void {
      this.showModalDesign = true;
    }

    popUpgradeToPremiumMessage(): void {
      if (this.isPremiumSubscription) return;

      this.$notify({
        type: "info",
        text: this.$tc("common.notifications.upgradeToPremium"),
        ignoreDuplicates: true
      } as NotificationOptions);
    }

    prevForm: PublicWebForm | {} = {};

    publicWebForm: PublicWebForm = {
      active: false,
      url: "",
      powered_by_lazzaro: false,
      logo: [] as any,
      mainImage: {} as any,
      orgname: "",
      textColor: "",
      primaryColor: "",
      secondaryColor: "",
      projectColor:"",
      memberColor:"",
      coursesColor:"",
      heroColor:"",
      shopColor:"",
      aboutUsColor:"",
      eventsColor:"",
      volunteersColor:"",
      logoColor:"",
      phone: "",
      address: "",
      causes: false,
      events: false,
      volunteers: false,
      courses: false,
      market: false,
      partners: false,
      donations: false,
      impact: false,
      facebook: "",
      linkedin: "",
      instagram: "",
      twitter: "",
      web: "",
      team: [],
      description: "",
      mission: "",
      vision: "",
      values: "",
      accountability: [] as any,
      desc_title: "",
      desc_subtitle: "",
      desc_title_description: "",
      desc_image: {} as any,
      desc_description: "",
      impact_field_one: "",
      impact_field_two: "",
      impact_field_three: "",
      impact_field_four: "",
      impact_value_one: 0,
      impact_value_two: 0,
      impact_value_three: 0,
      impact_value_four: 0,
      terms_description: "",
      transparency_description: "",
      logos: [],
      chosenTemplateId: "e1849ec4-d72e-4c4c-94d0-c3395f39e7da",
      google_analytics_id: ""
    };

    teamForm = {
      image: null,
      name: "",
      position: "",
      linkedin: ""
    };

    oldLogo = [] as any;
    oldMainImage = [] as any;

    teamFields = [
      { id: "name", label: this.$t("web.public.teamForm.name") },
      { id: "position", label: this.$t("web.public.teamForm.position") },
      { id: "linkedin", label: this.$t("web.public.teamForm.linkedin") },
      { id: "delete", label: this.$t("web.public.teamForm.delete") }
    ];

    @auth.State("id")
    public ongId!: string;

    @auth.State("email")
    public email!: string;

    @auth.Action
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateFeatures!: (payload: any) => void;

    loaded = false;
    LOGOS_LIMIT: number = 10;

    get isFormChanged(): boolean {
      if (isObjectEmpty(this.prevForm)) return false;
      return !isEqual(this.prevForm, this.publicWebForm);
    }

    addImageInput() {
      if (this.publicWebForm.logos.length === 9) {
        this.$notify({
          type: "error",
          text: this.$tc("web.public.logosForm.logo.limit")
        });
      }
      this.publicWebForm.logos.push({
        logo:
          "https://i.pinimg.com/originals/9b/96/92/9b9692c9f0db9f6276e6bd29a98c25e0.png",
        id: `logo${this.publicWebForm.logos.length}`
      });
    }

    async uploadLogo({ target }: any): Promise<void> {
      const file = (target as HTMLInputElement).files?.[0] as File;

      this.publicWebForm.logos = this.publicWebForm.logos.map(input => {
        if (input.id === target.id) {
          return {
            ...input,
            logo: URL.createObjectURL(file as Blob)
          };
        }
        return input;
      });

      try {
        const logo = (await toBase64(file)) as string;
        await apiOngs.postLogos(this.ongId, { logo });
      } catch (errors) {
        this.$notify({
          type: "error",
          text: this.$tc("web.public.logosForm.logo.limit")
        });
      }
    }

    async deleteLogo(logoId: string): Promise<void> {
      try {
        await apiOngs.deleteLogo(this.ongId, { logoId });

        this.publicWebForm.logos = this.publicWebForm.logos.filter(logo => {
          return logo.id !== logoId;
        });

        this.$notify({
          type: "success",
          text: this.$tc("web.public.logosForm.logo.delete")
        });
      } catch (error) {
        this.$notify({
          type: "error",
          text: this.$tc("web.public.logosForm.logo.error")
        });
      }
    }

    async handlePublishWebsite(
      active: boolean,
      { templateId }: { templateId: string }
    ) {
      try {
        if (active === this.ongConfiguration.active) return;

        if (active) {
          this.modalText = {
            title: this.$tc("web.public.websiteStatus.publishWebsite.title"),
            subtitle: this.$tc(
              "web.public.websiteStatus.publishWebsite.subtitle"
            )
          };

          this.onModalOpen();
          await apiOngs.publishWebsite({ ongId: this.ongId, templateId });
        } else {
          this.modalText = {
            title: this.$tc("web.public.websiteStatus.unpublishWebsite.title"),
            subtitle: this.$tc(
              "web.public.websiteStatus.unpublishWebsite.subtitle"
            )
          };

          this.onModalOpen();

          await apiOngs.unpublishWebsite({ ongId: this.ongId, templateId });
        }

        this.onModalClose();
      } catch (err) {
        this.$notify({
          type: "error",
          text: this.$tc("web.public.notify.error")
        });
        this.onModalClose();
      }
    }

    async getEvents() {
      try {
        const { data } = await apiEvents.getEvents(this.ongId);
        return data;
      } catch (err) {
        this.$notify({
          type: "error",
          text: this.$tc("web.public.notify.error")
        });
      }
    }

    async onPublicWebSubmit() {
      const transparency = (
        await parseFile(this.publicWebForm.accountability)
      ).map((file: any, key: any) => {
        return {
          file,
          title: this.publicWebForm.accountability.files[key].name
        };
      });
      const logoTrimmed = (
        await parseFile(this.publicWebForm.logo)
      )[0] as string;
      const defaultTrimmed = (
        await parseFile(this.publicWebForm.mainImage)
      )[0] as string;
      const descImage = (
        await parseFile(this.publicWebForm.desc_image)
      )[0] as string;

      const team = [];
      for await (const member of this.publicWebForm.team) {
        if (member.photo && member.photo.files) {
          let photo = await parseFile(member.photo);
          photo = photo[0];
          team.push({ ...member, photo });
        } else {
          team.push({ ...member });
        }
      }

      const imagesToRemove = [];

      if (
        !this.publicWebForm.logo.files ||
        !this.publicWebForm.logo.files[0].file.url
      ) {
        imagesToRemove.push(this.oldLogo[0].url);
      }

      if (
        !this.publicWebForm.mainImage.files ||
        !this.publicWebForm.mainImage.files[0].file.url
      ) {
        imagesToRemove.push(this.oldMainImage[0].url);
      }

      const hasTemplateChanged =
        this.publicWebForm.chosenTemplateId !==
        this.ongConfiguration.template_id;

      if (hasTemplateChanged) {
        this.modalText = {
          title: this.$tc(
            "web.public.chooseTemplate.templateChangeModal.title"
          ),
          subtitle: this.$tc(
            "web.public.chooseTemplate.templateChangeModal.subtitle"
          )
        };

        this.onModalOpen();
      }

      // to disable submit button
      this.prevForm = {};

      const availableEvents = await this.getEvents();
      const isAnyCourse = availableEvents?.find(event => event.course)
      const isAnyEvent = availableEvents?.find(event => !event.course)
      
      Promise.all([
        apiOngs.postPlatformConfig(this.ongId, {
          ...this.ongConfiguration,
          active: this.publicWebForm.active,
          powered_by_lazzaro: this.publicWebForm.powered_by_lazzaro,
          url: this.publicWebForm.url,
          template_id: this.publicWebForm.chosenTemplateId,
          google_analytics_id: this.publicWebForm.google_analytics_id
        }),
        apiOngs.postContact(this.ongId, {
          address: this.publicWebForm.address,
          email: this.email,
          phone: this.publicWebForm.phone
        }),
        apiOngs.postPlatformFeatures(this.ongId, {
          causes: this.publicWebForm.causes,
          events: this.publicWebForm.events,
          volunteers: this.publicWebForm.volunteers,
          courses: this.publicWebForm.courses,
          market: this.publicWebForm.market,
          partners: this.publicWebForm.partners,
          donations: this.publicWebForm.donations,
          impact: this.publicWebForm.impact,
          logos: !!this.publicWebForm.logo.files?.[0].file.url
        }),
        apiOngs.postRRSS(this.ongId, {
          linkedin: this.publicWebForm.linkedin,
          facebook: this.publicWebForm.facebook,
          twitter: this.publicWebForm.twitter,
          instagram: this.publicWebForm.instagram,
          web: this.publicWebForm.web
        }),
        apiOngs.postTermsAndConditions(this.ongId, {
          text: this.publicWebForm.terms_description
        }),
        apiOngs.postTransparency(this.ongId, {
          files: transparency,
          transparency_description: this.publicWebForm.transparency_description
        }),
        apiOngs.postRrss(this.ongId, {
          facebook: this.publicWebForm.facebook,
          instagram: this.publicWebForm.instagram,
          twitter: this.publicWebForm.twitter,
          linkedin: this.publicWebForm.linkedin,
          web: this.publicWebForm.web
        }),
        apiOngs.postTeam(this.ongId, team),
        apiBrand(this.ongId, {
          logo: logoTrimmed,
          default_img: defaultTrimmed,
          name: this.publicWebForm.orgname,
          primary_color_hex: this.publicWebForm.primaryColor,
          primary_color_rgb: this.hexToRgbA(this.publicWebForm.primaryColor),
          secondary_color_hex: this.publicWebForm.secondaryColor,
          secondary_color_rgb: this.hexToRgbA(
            this.publicWebForm.secondaryColor
          ),
          imagesToRemove
        }),
        apiOngs.postDescription(this.ongId, {
          title: this.publicWebForm.desc_title,
          subtitle: this.publicWebForm.desc_subtitle,
          title_description: this.publicWebForm.desc_title_description,
          image: descImage,
          description: this.publicWebForm.desc_description
        }),
        apiOngs.postImpactData(this.ongId, [
          {
            name: this.publicWebForm.impact_field_one,
            amount: this.publicWebForm.impact_value_one
          },
          {
            name: this.publicWebForm.impact_field_two,
            amount: this.publicWebForm.impact_value_two
          },
          {
            name: this.publicWebForm.impact_field_three,
            amount: this.publicWebForm.impact_value_three
          },
          {
            name: this.publicWebForm.impact_field_four,
            amount: this.publicWebForm.impact_value_four
          }
        ]),
        apiOngs.postSectionsStyle(this.ongId, {
          section:"web",
          propierties:{
            projects:{
              backgroundColor:this.publicWebForm.projectColor
            },
            members:{
              backgroundColor:this.publicWebForm.memberColor
            },
            courses:{
              backgroundColor:this.publicWebForm.coursesColor
            },
            hero:{
              backgroundColor:this.publicWebForm.heroColor
            },
            shop:{
              backgroundColor:this.publicWebForm.shopColor
            },
            aboutUs:{
              backgroundColor:this.publicWebForm.aboutUsColor
            },
            events:{
              backgroundColor:this.publicWebForm.eventsColor
            },
            volunteers:{
              backgroundColor:this.publicWebForm.volunteersColor
            },
            logo:{
              backgroundColor:this.publicWebForm.logoColor
            }
          }
        }),
        this.handlePublishWebsite(this.publicWebForm.active, {
          templateId: this.publicWebForm.chosenTemplateId
        })
      ])
        .then(async () => {
          this.$notify({
            type: "success",
            text: this.$tc("web.public.notify.success"),
            ignoreDuplicates: true
          } as NotificationOptions);

          await this.updateFeatures(this.ongId);
          this.prevForm = cloneDeep(this.publicWebForm);
        })
        .catch(() => {
          this.$notify({
            type: "error",
            text: this.$tc("web.public.notify.error"),
            ignoreDuplicates: true
          } as NotificationOptions);
        });
    }

    hexToRgbA(hex: string) {
      let c: any;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          // eslint-disable-next-line
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(+c >> 16) & 255, (+c >> 8) & 255, +c & 255].join(",") +
          ",1)"
        );
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    removeTeamMember(e: any) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const teamArr = this.publicWebForm.team as any[];
      const memberIdx = teamArr.findIndex(el => el === e);
      teamArr.splice(memberIdx, 1);
    }

    onTeamSubmit() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const teamArr = this.publicWebForm.team as any[];

      teamArr.push({
        name: this.teamForm.name,
        position: this.teamForm.position,
        linkedin: this.teamForm.linkedin,
        photo: this.teamForm.image
      });

      this.teamForm.image = null;
      this.teamForm.name = "";
      this.teamForm.position = "";
      this.teamForm.linkedin = "";
    }

    async mounted() {
      this.isPremiumSubscription = (
        await checkSubscriptionPlan(this.ongId)
      ).isPremiumSubscription;

      Promise.all([
        apiOngs.getAllPlatformConfig(this.ongId).then(({ data }) => {
          this.publicWebForm.chosenTemplateId =
            data.platformConfig?.template_id;
          this.publicWebForm.google_analytics_id =
            data.platformConfig.google_analytics_id;
          this.publicWebForm.active = data.platformConfig?.active;
          this.publicWebForm.url = data.platformConfig?.url;
          this.publicWebForm.powered_by_lazzaro =
            data.platformConfig?.powered_by_lazzaro;
          this.publicWebForm.logo = [{ url: data.brand?.logo }];
          this.publicWebForm.mainImage = [{ url: data.brand?.default_img }];
          this.oldLogo = [{ url: data.brand?.logo }];
          this.oldMainImage = [{ url: data.brand?.default_img }];
          this.publicWebForm.terms_description =
            data.brand?.terms_and_conditions;
          this.publicWebForm.orgname = data.brand?.name;
          this.publicWebForm.primaryColor = data.brand?.primary_color_hex;
          this.publicWebForm.secondaryColor = data.brand?.secondary_color_hex;
          this.publicWebForm.phone = data.contact?.phone;
          this.publicWebForm.address = data.contact?.address;
          this.publicWebForm.causes = data.features?.causes;
          this.publicWebForm.events = data.features?.events;
          this.publicWebForm.volunteers = data.features?.volunteers;
          this.publicWebForm.courses = data.features?.courses;
          this.publicWebForm.market = data.features?.market;
          this.publicWebForm.partners = data.features?.partners;
          this.publicWebForm.impact = data.features?.impact;
          this.publicWebForm.donations = data.features?.donations;
          this.publicWebForm.facebook = data.rrss?.facebook;
          this.publicWebForm.linkedin = data.rrss?.linkedin;
          this.publicWebForm.instagram = data.rrss?.instagram;
          this.publicWebForm.twitter = data.rrss?.twitter;
          this.publicWebForm.web = data.rrss?.web;
          this.publicWebForm.desc_title = data.description?.title;
          this.publicWebForm.desc_subtitle = data.description?.subtitle;
          this.publicWebForm.desc_title_description =
            data.description?.title_description;
          this.publicWebForm.desc_image = [{ url: data.description?.img_url }];
          this.publicWebForm.desc_description = data.description?.description;
          this.publicWebForm.impact_field_one = data.impactData[0]?.name;
          this.publicWebForm.impact_value_one = isNaN(
            +data.impactData[0]?.amount
          )
            ? 0
            : +data.impactData[0]?.amount;
          this.publicWebForm.impact_field_two = data.impactData[1]?.name;
          this.publicWebForm.impact_value_two = isNaN(
            +data.impactData[1]?.amount
          )
            ? 0
            : +data.impactData[1]?.amount;
          this.publicWebForm.impact_field_three = data.impactData[2]?.name;
          this.publicWebForm.impact_value_three = isNaN(
            +data.impactData[2]?.amount
          )
            ? 0
            : +data.impactData[2]?.amount;
          this.publicWebForm.impact_field_four = data.impactData[3]?.name;
          this.publicWebForm.impact_value_four = isNaN(
            +data.impactData[3]?.amount
          )
            ? 0
            : +data.impactData[3]?.amount;

          this.publicWebForm.team = data.team?.map(member => ({
            photo: member.img_url,
            name: member.name,
            position: member.position,
            linkedin: member.linkedin
          }));
        }),
        apiOngs.getTransparency(this.ongId).then(({ data }) => {
          this.publicWebForm.accountability = data?.files?.map(file => ({
            name: file.title,
            url: file.file_url
          }));

          this.publicWebForm.transparency_description =
            data?.description?.transparency_description;
        }),
        apiOngs.getSectionsStyle(this.ongId).then(({ data }) => {
          this.publicWebForm.coursesColor = data?.propierties?.courses?.backgroundColor;
          this.publicWebForm.heroColor = data?.propierties?.hero?.backgroundColor;
          this.publicWebForm.eventsColor = data?.propierties?.events?.backgroundColor;
          this.publicWebForm.aboutUsColor = data?.propierties?.aboutUs?.backgroundColor;
          this.publicWebForm.memberColor = data?.propierties?.members?.backgroundColor;
          this.publicWebForm.projectColor = data?.propierties?.projects?.backgroundColor;
          this.publicWebForm.shopColor = data?.propierties?.shop?.backgroundColor;
          this.publicWebForm.volunteersColor = data?.propierties?.volunteers?.backgroundColor;
          this.publicWebForm.logoColor = data?.propierties?.logo?.backgroundColor;
        })
      ]).then(() => {
        this.loaded = true;
      });

      const { data } = await apiOngs.getLogos(this.ongId);

      this.publicWebForm.logos = data.map(({ logo, id }) => ({ logo, id }));

      // not perfect but it works for now
      setTimeout(() => {
        this.prevForm = cloneDeep(this.publicWebForm);
      }, 3000);
    }
  }
